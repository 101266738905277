/* eslint-disable no-unused-vars */
/* eslint-disable  react-hooks/exhaustive-deps */
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import { FILTER_BY_SEARCH, FILTER_BY_SIZE_TYPE, MAX_WIDTH_100, SPEC_HEIGHT, SPEC_LENGTH, WIDTH_100 } from "../../utils/constants/GlobalConstants";
import { FILTER_BY_SIZE_DROPDOWN_DATA } from "../../utils/constants/GlobalFormConstants";
import { TextTypography } from "../common/typography/TextTypography";
import { Dropdown } from "../common/Dropdown/Dropdown";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import { getPartTypeChange } from "../../services/FilterByDimensionsService";

const FilterBySize = memo((props) => {
  const [partType, setPartType] = useState(null);
  const [SelectedStyle, setSelectedStyle] = useState(null);
  const [, setShowSizeFilter] = useState(false);
  const [partData, setPartData] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isStyleDropdownEnabled, setIsStyleDropdownEnabled] = useState(true);
  const [apiError, setApiError] = useState({ val: false, messages: "" });

  useEffect(() => {
    if (props.reset !== FILTER_BY_SIZE_TYPE) {
      setPartType(null);
      setSelectedStyle(null);
      setStyleData(null);
      setPartData(null);
      setIsStyleDropdownEnabled(true);
    }
  }, [props.reset]);

  useEffect(() => {
  
    setSelectedStyle(null) 
   
 }, [partType]);

  const handlePartTypeChange = async (selectedValue) => {
    setPartType(selectedValue);
    props.onstyleSelect(null, partData, []);

    setShowSizeFilter(selectedValue !== "");
    setIsStyleDropdownEnabled(false);
    props.onPartTypeSelect(selectedValue, null);
    try {
      const payload = {
        partType: selectedValue,
        apiName: "style",
      };
      const response = await getPartTypeChange(payload);

      const styles = response.styles.map((item) => ({
        value: item,
        label: item,
      }));
      setStyleData(styles);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const handleStyleChange = async (selectedValue) => {
    if (!selectedValue) {
      return;
    }
    setSelectedStyle(selectedValue);
    const data = {
      val: true,
      searchType: FILTER_BY_SIZE_TYPE,
    };
    props.handleSearch(data);
    props.onstyleSelect(selectedValue, partData, styleData);
    const payload = {
      partType: partType,
      apiName: "measurements",
      style: selectedValue,
    };
    try {
      let formattedData;
      const response = await getPartTypeChange(payload);
      if (response && response.measurements) {
        const { qualification, measurementValues } = response.measurements;
        if (
          qualification ===  SPEC_HEIGHT ||
          qualification === SPEC_LENGTH
        ) {
          formattedData = Array.from(
            new Set(
              measurementValues.map((val) => {
                const numericPart = val.split(" ")[0];
                return Math.floor(parseFloat(numericPart));
              })
            )
          ).map(String);
        } else {
          formattedData = measurementValues;
        }
        props.onMeasurementsFetched({
          qualification: qualification,
          measurementValues: formattedData,
        });
      }
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };
  return (
    <>
      <Box component="fieldset" className="fieldset-style-main">
        <form className="lg:mx-4 mb-4 ">
          <TextTypography text={FILTER_BY_SEARCH} />
          <Dropdown
            style={isMobile ? { ...WIDTH_100, ...MAX_WIDTH_100 } : {}}
            dimension={!isMobile}
            label={" Part Type"}
            data={partData}
            selectValue={(selectedItem) =>
              handlePartTypeChange(selectedItem.label)
            }
            disabled={false}
            value={partType}
            onOpen={() => setPartData(FILTER_BY_SIZE_DROPDOWN_DATA)}
          />
          <Dropdown
            style={isMobile ? { ...WIDTH_100, ...MAX_WIDTH_100 } : {}}
            dimension={!isMobile}
            label={" Style"}
            data={styleData}
            selectValue={(selectedItem) =>
              handleStyleChange(selectedItem.label)
            }
            testid="style"
            disabled={isStyleDropdownEnabled}
            value={SelectedStyle}
          />
        </form>
      </Box>
      <SimpleSnackbar apiError={apiError} />
    </>
  );
});

export default FilterBySize;
