// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollable-container {
  max-height: 800px;
  overflow-y: auto;
}
.dropdown-container {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.dropdown-item {
  margin-bottom: 16px;
}
.disclaimer-text {
  font-size: 14px;
  line-height: 1.5;
  color: red;
  margin-bottom: 20px;
}
.dropdown-alignment {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img-container {
  margin-left: 30px;
  display:flex;
  flex-direction: row;
}
.accordion-container {
  margin-top: 36px;
}
.result-table {
  margin-top: 16px;
}
.css-d51jgl-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 50px !important;
  height: 50px !important;
}
.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {
  margin: 0px 0 !important;
}
.column-flex {
  flex-direction: column;
}

.row-flex {
  display: flex;
  flex-direction: "row";
  align-items: "center";
}

/*Tablet*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .dropdown-container {
    width: 60%;
  }
}

/*Mobile*/
@media only screen and (max-width: 640px) {
  .dropdown-container {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/styles/FilterLookupBySize.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;AACZ;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,2BAA2B;EAC3B,uBAAuB;AACzB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,qBAAqB;AACvB;;AAEA,SAAS;AACT;EACE;IACE,UAAU;EACZ;AACF;;AAEA,SAAS;AACT;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".scrollable-container {\n  max-height: 800px;\n  overflow-y: auto;\n}\n.dropdown-container {\n  display: flex;\n  flex-direction: column;\n  width: 25%;\n}\n\n.dropdown-item {\n  margin-bottom: 16px;\n}\n.disclaimer-text {\n  font-size: 14px;\n  line-height: 1.5;\n  color: red;\n  margin-bottom: 20px;\n}\n.dropdown-alignment {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.img-container {\n  margin-left: 30px;\n  display:flex;\n  flex-direction: row;\n}\n.accordion-container {\n  margin-top: 36px;\n}\n.result-table {\n  margin-top: 16px;\n}\n.css-d51jgl-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {\n  min-height: 50px !important;\n  height: 50px !important;\n}\n.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded {\n  margin: 0px 0 !important;\n}\n.column-flex {\n  flex-direction: column;\n}\n\n.row-flex {\n  display: flex;\n  flex-direction: \"row\";\n  align-items: \"center\";\n}\n\n/*Tablet*/\n@media only screen and (min-width: 768px) and (max-width: 1023px) {\n  .dropdown-container {\n    width: 60%;\n  }\n}\n\n/*Mobile*/\n@media only screen and (max-width: 640px) {\n  .dropdown-container {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
