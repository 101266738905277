import { Box } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import TextInput from "../common/textInput/TextInput";
import {
  COMPETITOR_PARTIAL_LOOKUP,
  ERR_MSG_PART_NUMBER,
  FILTER_SEARCH_TYPE,
  PARTIAL,
  PARTIAL_SEARCH_TYPE,
  PARTNUM_SEARCH,
  SEARCH_FILTER_HEADER,
  SEARCH_FILTER_PARTIAL_PLACEHOLDER,
  SEARCH_FILTER_PLACEHOLDER,
} from "../../utils/constants/GlobalConstants";
import { VALID_SEARCH_REGEX } from "../../utils/constants/GlobalFormConstants";
import { TextTypography } from "../common/typography/TextTypography";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import { competitorInterchangeData } from "../../services/ProductServices";

const SearchByPart = memo((props) => {
  const [partNum, setPartNum] = React.useState();
  const [filterNum, setfilterNum] = React.useState();
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (props.reset !== PARTIAL_SEARCH_TYPE) {
      setPartNum("");
    }
    if (props.reset !== FILTER_SEARCH_TYPE) {
      setfilterNum("");
    }
  }, [props.reset]);
  const regex = VALID_SEARCH_REGEX;
  const handleOnChange = (e) => {
    const val = e.target.value;
    const valid = val ? regex.test(val) : true;
    setIsValid(valid);
    setPartNum(e.target.value);
    setfilterNum(e.target.value);
  };

  const getPartData = async (searchArray) => {
    try {
      const res = await competitorInterchangeData({
        partNumbers: searchArray,
        apiName: props?.partial ? PARTIAL : PARTNUM_SEARCH,
      });
      return res;
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };
  const onPressAdd = async (e) => {
    e.preventDefault();
    if (isValid) {
      let searchArray = props?.partial
        ? partNum.split(",")
        : filterNum.split(",");
      let res = "";
      if ((searchArray?.length > 0) && (searchArray[0])) {
        res = await getPartData(searchArray);
      }
      const partSearch = props.partial ? partNum : filterNum;
      const data = {
        res: res?.partNumberDetailsList,
        val: true,
        searchType: props.partial ? PARTIAL_SEARCH_TYPE : FILTER_SEARCH_TYPE,
        partSearch,
        searchArray,
        partial: props?.partial,
      };

      props.handleSearch(data);
    } else {
      setApiError((val) => ({ ...val, val: true, message: ERR_MSG_PART_NUMBER }));
    }
  };

  return (
    <div className="zoom">
      <Box component="fieldset" className="fieldset-style-main">
        <form className="lg:mx-4 mb-4 ">
          <TextTypography
            text={
              props.partial ? COMPETITOR_PARTIAL_LOOKUP : SEARCH_FILTER_HEADER
            }
          />
          <div className="flex rounded-lg overflow-hidden">
            {props.partial ? (
              <TextInput
                className="min-h-[48px] focus:outline-none grow px-4 bg-[#a3bef124] text-sm"
                type={"text"}
                placeholder={SEARCH_FILTER_PARTIAL_PLACEHOLDER}
                required={true}
                value={partNum}
                error={!isValid}
                style={{
                  borderBottom: isValid ? 'inherit' : '1px solid red',
                }}
                onChange={(e) => handleOnChange(e)}
              />
            ) : (
              <TextInput
                className="min-h-[48px] focus:outline-none grow px-4 bg-[#a3bef124] text-sm"
                type={"text"}
                placeholder={SEARCH_FILTER_PLACEHOLDER}
                required={true}
                error={!isValid}
                style={{
                  borderBottom: isValid ? 'inherit' : '1px solid red',
                }}
                value={filterNum}
                onChange={(e) => handleOnChange(e)}
              />
            )}
            <button
              className="bg-blue-600 text-white hover:bg-opacity-90 min-h-[48px] px-4"
              type="submit"
              onClick={(e) => onPressAdd(e)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </form>
      </Box>
      <SimpleSnackbar apiError={apiError} />
    </div>
  );
});

export default SearchByPart;
