"use client";
/* eslint-disable  react-hooks/exhaustive-deps */

import * as React from "react";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "../../../utils/lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover";
import { NO_ITEM_FOUND_TEXT } from "../../../utils/constants/GlobalConstants";
import { useMediaQuery, useTheme } from "@mui/material";

export function Dropdown({
  label,
  data,
  selectValue,
  dimension,
  disabled,
  onOpen,
  inverseSort,
  ...props
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(props.value ? props.value : null);
  const [dropDownData, setData] = React.useState(data);
  const [type, setType] = React.useState(props.type ? props.type : null)

  React.useEffect(() => {
    setType(props.type)
  },[props.type])

  React.useEffect(() => {
    if (data == null) {
      setValue("");
      setData(data);
    } else if (inverseSort && data) {
      const sortedList = data.sort((a, b) =>
        a.label.toUpperCase() > b.label.toUpperCase() ? -1 : 1
      );
      setData(sortedList);
    } else {
      const sortedList = data.sort((a, b) =>
        a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1
      );
      setData(sortedList);
    }
  }, [data, inverseSort]);

  React.useEffect(() => {
    if(!type){
      setValue(props.value);
    }
  }, [props]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  let dropValue= (
    dropDownData &&
    dropDownData.find((item) => item.label === value)
  )?.label;
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between mb-3"
          disabled={disabled ? disabled : false}
          onClick={onOpen ? () => onOpen() : () => { }}
        > 
        
          {value
            ? dropValue?.length > 35 ? dropValue?.substring(0,35)+"..." : dropValue
            : `Select ${label}...`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={isMobile ? "w-[320px] p-0" : "w-[320px] p-0 app-container"}
      >
        <Command
          filter={(value, search) => {
            if (value.toUpperCase().includes(search.toUpperCase())) return 1;
            return 0;
          }}
        >
          <CommandInput placeholder={`Search ${label}...`} />
          <CommandList>
            <CommandEmpty>{NO_ITEM_FOUND_TEXT}</CommandEmpty>
            <CommandGroup>
              {data &&
                data.map((item) => {
                  return (
                    <CommandItem
                      key={item.value}
                      value={item.value}
                      onSelect={(currentValue) => {
                        selectValue(
                          data.find((item) => item.label === currentValue)
                        );
                        setValue(currentValue);
                        setOpen(false);
                      }}
                    >
                      <Check
                        className={cn(
                          "mr-2 h-4 w-4",
                          value === item.value
                            ? "opacity-100"
                            : value === item.label
                              ? "opacity-100"
                              : "opacity-0"
                        )}
                      />
                      {item.label}
                    </CommandItem>
                  );
                })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
