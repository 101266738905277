import { Box } from "@mui/material";
import React, { memo } from "react";
import GridContainer from "../common/grid/GridContainer";
import GridItem from "../common/grid/GridItem";
import image1 from "../../assets/images/pdf3.png";
import { TextTypography } from "../common/typography/TextTypography";
import { FONT400, FONTSIZE, MB_0, NAPA_POWERSPORTS_TITLE, PDF_CATALOGS, POWER_SPORTS } from "../../utils/constants/GlobalConstants";
import { PDF_SECTION_ITEMS } from "../../utils/constants/GlobalFormConstants";
const Pdfsection = memo((props) => {
  return (
    <div className="zoom">
      <Box component="fieldset" className="fieldset-style-main">
        <form className="lg:mx-4 ">
          <TextTypography text={PDF_CATALOGS} />
          <GridContainer spacing={2}>
            {PDF_SECTION_ITEMS.map((item, index) => {
              const isPowerSports = item.name.toLowerCase() ===  POWER_SPORTS;
              return (
                <GridItem key={index} xs={12} sm={12} lg={4} md={4} xl={4}>
                  <div
                    className="pdf-container"
                    style={{
                      cursor: isPowerSports ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                      if (!isPowerSports) {
                        window.open(`${item.url}`, "_blank");
                      }
                    }}
                    title={
                      isPowerSports
                        ? NAPA_POWERSPORTS_TITLE
                        : ""
                    }
                  >
                    <img
                      className="Centered"
                      src={image1}
                      alt="no-image"
                      height="100px"
                      width="40px"
                    />
                    <TextTypography
                      text={item.name}
                      marginBottom={{ ...MB_0 }}
                      fontSize={{ ...FONTSIZE }}
                      fontWeight={{ ...FONT400 }}
                    />
                  </div>
                </GridItem>
              );
            })}
          </GridContainer>
        </form>
      </Box>
    </div>
  );
});

export default Pdfsection;
