import GridContainer from "../../components/common/grid/GridContainer";
import GridItem from "../../components/common/grid/GridItem";
import "./Home.css";
import CompetitorInterchange from "../../components/home/CompetitorInterchange";
import ApplicationLookup from "../../components/home/ApplicationLookup";
import FilterBySize from "../../components/home/FilterBySize";
import React, { useRef, useState, useEffect } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import DialogView from "../../components/common/dialogView/DialogView";
import PartDetail from "../../components/partDetails/PartDetail";
import DataTable from "../../components/common/dataTable/DataTable";
import DataList from "../../components/common/dataList/DataList";
import { AppBody2Text } from "../../components/common/appHeaders/AppHeaders";
import {
  APPLICATION_LOOKUP,
  APPLICATION_LOOKUP_TYPE,
  APP_SUBTITLE,
  APP_TITLE1,
  COMPETITOR_INTERCHANGE,
  COMPETITOR_INTERCHANGE_TYPE,
  FILTER_BY_SEARCH,
  FILTER_BY_SIZE_TYPE,
  FILTER_SEARCH,
  FILTER_SEARCH_TYPE,
  GO_LABEL,
  HOME_GRID_STYLE,
  HOME_MAIN_STYLE,
  MB_10,
  PARTIAL_SEARCH_TYPE,
  PART_SEARCH_REF_TEXT,
  SEARCH_RESULT_TEXT,
  COMPETITOR_SEARCH_RESULT,
  FILTER_SEARCH_RESULT,
  COMPETITOR_PARTIAL_SEARCH,
  FILTERLOOKUP_TITLE
} from "../../utils/constants/GlobalConstants";
import ErrorView from "../../components/common/errorView/ErrorView";
import SearchByPart from "../../components/home/SearchByPart";
import FilterLookupBySize from "../../components/home/FilterLookupBySize";
import { TextTypography } from "../../components/common/typography/TextTypography";
import { ErrorBoundary } from "../../components/common/errorBoundary/ErrorBuond";
import PdfSection from "../../components/home/PdfSection";

export const Home = () => {
  const resultRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [showResultContainer, setShowResultContainer] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [searchArray, setSearchArray] = useState([]);
  const [errWindowOpen, setErrWindowOpen] = useState(false);
  const [data, setData] = useState(null);
  const [showFilterLookupContainer, setShowFilterLookupContainer] =
    useState(false);
  const [SelectedStyle, setSelectedStyle] = useState(null);
  const [SelectedpartType, setSelectedPartType] = useState(null);
  const [applicationLookUpResult, setApplicationLookUpResult] = useState({
    val: false,
    result: {},
  });

  const [partData, setPartData] = useState([]);
  const [styleData, setStyleData] = useState([]);
  const [filterSearchRes, setFilterSearchRes] = useState([]);
  const [productDetailsData, setProductDetailsData] = useState([]);
  const [inputPartNum, setInputPartNum] = useState();
  const [qualification, setQualification] = useState("");
  const [measurementValues, setMeasurementValues] = useState([]);
  const [clearFilter, setClearFilter] = useState("");

  const onPressLink = (val) => {
    setProductDetailsData(val);
    setOpen(true);
  };

  const handleCloseErrWindow = () => {
    setErrWindowOpen(false);
    if (data) {
      setData(null);
    }
  };

  const handleResultContainer = (data) => {
    setInputPartNum(data?.partSearch);
    if (
      (data.searchType === COMPETITOR_INTERCHANGE_TYPE &&
        data.searchArray?.length > 0) ||
      data.partSearch ||
      data.partNum
    ) {
      setShowResultContainer(data.val);
      setApplicationLookUpResult((item) => ({
        ...item,
        val: data.val,
        result: data.result,
      }));
      setFilterSearchRes(data?.res);
      setShowFilterLookupContainer(data.val);
      setFilterType(data.searchType);
      resetFilters(data.searchType);
      setSearchArray(data.searchArray);
      if (data.val && resultRef) {
        setTimeout(() => {
          resultRef?.current?.scrollIntoView({ behavior: "smooth" });
        });
      }
    } else if (
      (data.searchType === COMPETITOR_INTERCHANGE_TYPE &&
        data.searchArray?.length > 0) ||
      data.partSearch
    ) {
      setShowResultContainer(data.val);
      setShowFilterLookupContainer(data.val);
      setFilterType(data.searchType);
      setSearchArray(data.searchArray);
      if (data.val) {
        setShowFilterLookupContainer(false);
      }
      if (data.val && resultRef) {
        setTimeout(() => {
          resultRef?.current?.scrollIntoView({ behavior: "smooth",scrollMargin:'10px' });
        });
      }
    } else {
      setData(data);
    }
  };

  useEffect(() => {
    if (data && data.searchType) {
      setErrWindowOpen(true);
    }
  }, [data]);

  const handleClose = () => {
    setOpen(false);
  };

  const resetFilters = (searchType) => {
    setClearFilter(searchType);
  };

  const handleFilterLookupContainer = (data) => {
    setShowFilterLookupContainer(data.val);
    setFilterType(data.searchType);
    resetFilters(data.searchType);
    if (data.val) {
      setShowResultContainer(false);
    }
    if (data.val && resultRef) {
      setTimeout(() => {
        resultRef?.current.scrollIntoView({ behavior: "smooth" ,scrollMargin:'10px'});
      });
    }
  };

  const handleDropdownClick = (searchType) => {
    setClearFilter(searchType);
  };

  const handleStyleSelection = (style, partData, styleData) => {
    setSelectedStyle(style);
    setPartData(partData);
    setStyleData(styleData);
  };

  const handlePartTypeSelection = (parttype,style) => {
    setSelectedPartType(parttype);
    setSelectedStyle(style);
  };

  const handleMeasurements = (data) => {
    setQualification(data.qualification);
    setMeasurementValues(data.measurementValues);
  };

  return (
    <ErrorBoundary>
      <div className="layout" style={HOME_MAIN_STYLE}>
        <DialogView
          from={"parts"}
          open={open}
          handleClose={() => handleClose()}
          width={"80%"}
          maxWidth={"75em!important"}
          heading={"Part Details"}
          childComponet={
            <PartDetail
              partNumber="MGA10039"
              productDetailsData={productDetailsData}
              closeDialog={() => {
                handleClose();
              }}
            />
          }
        />
        {data && (
          <DialogView
            from={"parts"}
            open={errWindowOpen}
            handleClose={() => handleCloseErrWindow()}
            width={"30%"}
            maxWidth={"75em!important"}
            heading={"Error"}
            childComponet={<ErrorView data={data && data} />}
          />
        )}

        <GridContainer rowSpacing={7} columnSpacing={3} sx={HOME_GRID_STYLE}>
          <GridItem xs={12} sm={12} lg={12} md={12} xl={12} sx={MB_10}>
            <h1 className="header">{APP_TITLE1}</h1>
            <p className="sub-head">{APP_SUBTITLE}</p>
          </GridItem>
          <GridItem xs={12} sm={12} lg={3} md={3} xl={3}>
            <div className="mb-8">
              <ApplicationLookup
                handleSearch={(val) => handleResultContainer(val)}
                testid={APPLICATION_LOOKUP}
                reset={clearFilter}
              />
            </div>
            <div>
              <FilterBySize
                handleDropdownClick={handleDropdownClick}
                handleSearch={(val) => handleFilterLookupContainer(val)}
                onstyleSelect={(selected, partData, styleData) =>
                  handleStyleSelection(selected, partData, styleData)
                }
                testid={FILTER_BY_SEARCH}
                onPartTypeSelect={handlePartTypeSelection}
                onMeasurementsFetched={handleMeasurements}
                reset={clearFilter}
                source={FILTER_BY_SIZE_TYPE}
              />
            </div>
          </GridItem>
          {/* competitor */}
          <GridItem xs={12} sm={12} lg={6} md={6} xl={6}>
            <div className="mb-8">
              <SearchByPart
                reset={clearFilter}
                handleSearch={(val) => handleResultContainer(val)}
                testid={FILTER_SEARCH}
              />
            </div>

            <div className="mb-8">
              <SearchByPart
                reset={clearFilter}
                handleSearch={(val) => handleResultContainer(val)}
                partial={true}
                testid={PARTIAL_SEARCH_TYPE}
              />
            </div>

            <div>
              <PdfSection />
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} lg={3} md={3} xl={3}>
            <CompetitorInterchange
              reset={clearFilter}
              handleSearch={(val) => handleResultContainer(val)}
              testid={COMPETITOR_INTERCHANGE}
            />
          </GridItem>
        </GridContainer>
        {/* search results */}
        <div ref={resultRef}>
          {showResultContainer && (
            <GridContainer spacing={2}>
              {filterType === COMPETITOR_INTERCHANGE_TYPE ||
              filterType !== APPLICATION_LOOKUP_TYPE
                ? searchArray &&
                searchArray.map((itm, i) => {
                  return (
                    <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                      <div className="item-container" >
                        <TextTypography
                          text={filterType === COMPETITOR_INTERCHANGE_TYPE ? `${COMPETITOR_SEARCH_RESULT} ${itm?.toUpperCase()}` :
                          filterType === FILTER_SEARCH_TYPE ? `${FILTER_SEARCH_RESULT} ${itm?.toUpperCase()}` :
                          `${COMPETITOR_PARTIAL_SEARCH} ${itm?.toUpperCase()}`}
                          marginBottom={"0px !important"}
                        />
                      </div>
                      {isMobile ? (
                        <DataList
                          inputPartNum={inputPartNum}
                          filterSearchRes={filterSearchRes &&
                            filterSearchRes[i]?.partNumberDetails
                          }
                          source={filterType === PARTIAL_SEARCH_TYPE ? PARTIAL_SEARCH_TYPE:"filterSearch"}
                          onPressLink={(val) => onPressLink(val)}
                        />
                      ) : (
                        <DataTable
                          inputPartNum={inputPartNum}
                          filterSearchRes={filterSearchRes &&
                            filterSearchRes[i]?.partNumberDetails
                          }
                          source={filterType === PARTIAL_SEARCH_TYPE ? PARTIAL_SEARCH_TYPE:"filterSearch"}
                          onPressLink={(val) => onPressLink(val)}
                        />
                      )}
                    </GridItem>
                  );
                })
                : filterType !== APPLICATION_LOOKUP_TYPE && (
                    <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                      <div className="item-container">
                        <TextTypography
                          text={`SEARCH RESULT FOR: ${inputPartNum}`}
                          marginBottom={"0px !important"}
                        />
                      </div>
                      {isMobile ? (
                        <DataList
                          inputPartNum={inputPartNum}
                          filterSearchRes={
                            filterSearchRes[0]?.partNumberDetails
                          }
                          onPressLink={(val) => onPressLink(val)}
                          source={filterType}
                        />
                      ) : (
                        <DataTable
                          inputPartNum={inputPartNum}
                          filterSearchRes={
                            filterSearchRes[0]?.partNumberDetails
                          }
                          onPressLink={(val) => onPressLink(val)}
                          source={filterType}
                        />
                      )}
                    </GridItem>
                  )}

              {filterType !== APPLICATION_LOOKUP_TYPE && (
                <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                  <AppBody2Text text={PART_SEARCH_REF_TEXT} />
                </GridItem>
              )}
            </GridContainer>
          )}
          {showFilterLookupContainer && (filterType === FILTER_BY_SIZE_TYPE || filterType === "filterbysize1") && (
            <div>
              <div className="item-container" >
                <TextTypography
                  text={FILTERLOOKUP_TITLE}
                  marginBottom={"0px !important"}
                />

              </div>
              <FilterLookupBySize
              testid={GO_LABEL}
                style={SelectedStyle}
                partType={SelectedpartType}
                onPressLink={(val) => onPressLink(val)}
                partData={partData}
                styleData={styleData}
                qualification={qualification}
                measurementValues={measurementValues}
                onstyleSelect={(selected, partData, styleData) => handleStyleSelection(selected, partData, styleData)}
                onPartTypeSelect={(parttype, style) => handlePartTypeSelection(parttype, style)}
                handleSearch={(val) => handleFilterLookupContainer(val)}
                onMeasurementsFetched = {handleMeasurements}
                
              />
            </div>
          )}

          {applicationLookUpResult &&
            applicationLookUpResult.val &&
            filterType === APPLICATION_LOOKUP_TYPE && (
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                  <div className="item-container" >
                    <TextTypography
                      text={`${SEARCH_RESULT_TEXT} ${
                        applicationLookUpResult.result.length > 0
                          ? applicationLookUpResult.result[0].makeModel
                          : APPLICATION_LOOKUP
                      }`}
                      marginBottom={"0px !important"}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={12} lg={12} md={12} xl={12}>
                  {isMobile ? (
                    <DataList
                      source={filterType}
                      data={applicationLookUpResult.result}
                      onPressLink={(val) => onPressLink(val)}
                    />
                  ) : (
                    <DataTable
                      source={filterType}
                      data={applicationLookUpResult.result}
                      onPressLink={(val) => onPressLink(val)}
                    />
                  )}
                </GridItem>
              </GridContainer>
            )}
        </div>
      </div>
    </ErrorBoundary>
  );
};
