
/* eslint-disable no-unused-vars */
import axios from "axios"; import { postRequest } from "./RequestConfig";
export const getVehicleType = async () => {
  try {
    const response = await postRequest("filters", { apiType: "vehicleTypes" });
    return response.data.vehicleTypes;
  } catch (error) { throw error; }
};

export const getVehicleYears = async (selectedType) => {
  try {
    const response = await postRequest("filters", {
      apiType: "vehicleYears", type: selectedType,
    }); return response.data.vehicleYear;
  } catch (error) { throw error; }
};

export const getVehicleMakes = async (selectedType, selectedYear) => {
  try {
    const response = await
      postRequest("filters", { apiType: "vehicleMakes", type: selectedType, year: selectedYear, });
    return response.data.vehicleMake;
  } catch (error) { throw error; }
};

export const getVehicleModels = async (selectedType, selectedYear, selectedMake) => {
  try {
    const response = await postRequest("filters", {
      apiType: "vehicleModels", type: selectedType, year: selectedYear,
      make: selectedMake,
    }); return response.data.vehicleModel;
  } catch (error) { throw error; }
};

export const getVehicleEngines = async (selectedType, selectedYear, selectedMake, selectedModel) => {
  try {
    const response = await postRequest("filters", {
      apiType: "vehicleEnginesAndSubModelsAndFilters",
      type: selectedType, year: selectedYear, make: selectedMake, model: selectedModel, apiName: "applicationLookup",
    }); return response.data;
  } catch (error) { throw error; }
};

export const getVehicleSubModel = async () => {
  try {
    const response = await axios.get(
      process.env.PUBLIC_URL + "/vehicleEnginesAndSubModels.json"); return response.data.vehicleSubModel;
  } catch (error) { throw error; }
};
