import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  BGCOLOR_EFF6FF,
  MARGIN_PADD_02,
  POS_AB_RT_8,
  TEXT_LG,
} from "../../../utils/constants/GlobalConstants";
const DialogView = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      sx={props.style}
      className={!isMobile && "app-container"}
      PaperProps={{
        sx: {
          width: props.width ? props.width : "100%",
          maxWidth: props.maxWidth ? props.maxWidth : "65em!important",
          p: 0,
          m: 0,
          backgroundColor: props.background,
        },
      }}
    >
      <DialogTitle
        sx={{ ...MARGIN_PADD_02, ...TEXT_LG, ...BGCOLOR_EFF6FF }}
        id="customized-dialog-title"
      >
        {props.heading}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={POS_AB_RT_8}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers children={props.childComponet} />
    </Dialog>
  );
};

export default DialogView;
