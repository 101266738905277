import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Theme } from "./components/common/theme/Theme";
import Header from "./components/header/Header";
import NavigationProvider from "./utils/routes/Navigation";
import { Home } from "./views/home/Home";
import "./App.css";
import { useMediaQuery, useTheme } from "@mui/material";

function App() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div className={!isMobile && "app-container"}>
      <Theme>
        <Router>
          <NavigationProvider>
            <Header />
            <Routes>
              <Route path="/filter-tool" element={<Home />} />
              <Route path="/" element={<Home />} />
              <Route path="/filter-tool/home" element={<Home />} />
              <Route path="*" element={<Home />} />
            </Routes>
          </NavigationProvider>
        </Router>
      </Theme>
    </div>
  );
};

export default App;
