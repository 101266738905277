/* eslint-disable  react-hooks/exhaustive-deps */
/* eslint-disable  no-unreachable */
/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import { AIR_FILTER, APPLICATION_LOOKUP, APPLICATION_LOOKUP_TYPE, BREATHER_FILTER, CABIN_AIR_FILTER, ENGINE, FUEL_FILTER, HYDRAULIC_FILTER, MAKE, MODEL, MODEL_YEAR, OIL_FILTER, OTHERS, SUB_MODEL, TRANSMISSION_FILTER, VEHICLE_TYPE } from "../../utils/constants/GlobalConstants";
import { TextTypography } from "../common/typography/TextTypography";
import { Dropdown } from "../common/Dropdown/Dropdown";
import {
  getVehicleType,
  getVehicleYears,
  getVehicleMakes,
  getVehicleModels,
  getVehicleEngines,
} from "../../services/ApplicationLookupService";
import SimpleSnackbar from "../common/snackBar/SnackBar";

const ApplicationLookup = memo((props) => {
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleYears, setVehicleYears] = useState([]);
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [vehicleEngines, setVehicleEngines] = useState([]);
  const [vehicleSubModels, setVehicleSubModels] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMake, setSelectedMake] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedEngine, setSelectedEngine] = useState(null);
  const [selectedSubModels, setSelectedSubModels] = useState(null);
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [applicationData, setApplicationData] = useState();

  const cleanUp = () => {
    setSelectedYear(null);
    setSelectedMake(null);
    setSelectedModel(null);
    setSelectedEngine(null);
    setSelectedSubModels(null);
    setSelectedType(null);
    setVehicleYears(null);
    setVehicleMakes(null);
    setVehicleModels(null);
    setVehicleEngines(null);
    setVehicleSubModels(null);
  };

  useEffect(() => {
    if (props.reset !== APPLICATION_LOOKUP_TYPE) {
      setVehicleTypes(null);
      cleanUp();
    }
  }, [props.reset]);

  const getVehicleTypeData = async () => {
    try {
      const result = await getVehicleType();
      const res = result.map((item) => ({
        value: item.typeId,
        label: item.type,
      }));

      setVehicleTypes(res);
      setSelectedYear(null);
      setSelectedMake(null);
      setSelectedModel(null);
      setSelectedEngine(null);
      setSelectedSubModels(null);
      setVehicleYears(null);
      setVehicleMakes(null);
      setVehicleModels(null);
      setVehicleEngines(null);
      setVehicleSubModels(null);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const getVehicleYearData = async () => {
    try {
      const result = await getVehicleYears(selectedType.label);
      const res = result.map((item) => ({
        value: item,
        label: item,
      }));
      setVehicleYears(res);
      setSelectedMake(null);
      setSelectedModel(null);
      setSelectedEngine(null);
      setSelectedSubModels(null);
      setVehicleMakes(null);
      setVehicleModels(null);
      setVehicleEngines(null);
      setVehicleSubModels(null);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const getVehicleMakesData = async () => {
    try {
      const result = await getVehicleMakes(
        selectedType.label,
        selectedYear.label
      );
      const res = result.map((item) => ({
        value: item.makeId,
        label: item.make,
      }));
      setVehicleMakes(res);

      setSelectedModel(null);
      setSelectedEngine(null);
      setSelectedSubModels(null);
      setVehicleModels(null);
      setVehicleEngines(null);
      setVehicleSubModels(null);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const getVehicleModelsData = async () => {
    try {
      const result = await getVehicleModels(
        selectedType.label,
        selectedYear.label,
        selectedMake.label
      );
      const res = result.map((item) => ({
        value: Number(item.modelId),
        label: item.model,
      }));
      setVehicleModels(res);
      setSelectedEngine(null);
      setSelectedSubModels(null);
      setVehicleEngines(null);
      setVehicleSubModels(null);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const getVehicleEnginesData = async () => {
    try {
      const result = await getVehicleEngines(
        selectedType.label,
        selectedYear.label,
        selectedMake.label,
        selectedModel.label
      );
      const res =
        result["vehicleEngines"] &&
        result.vehicleEngines.map((item) => ({
          value: item.engineName,
          label: item.engineName,
          partsAndTypes: item.partsAndTypes,
        }));
      setVehicleEngines(res);

      const subModel =
        result["vehicleSubModel"] &&
        result.vehicleSubModel.map((item) => ({
          value: item.subModelId,
          label: item.subModelValue,
        }));
      setVehicleSubModels(subModel);

      const temp = [];
      res &&
        res.length > 0 &&
        res.forEach((item) => {
          temp.push({
            type: selectedType.label,
            year: selectedYear.label,
            makeModel:
              selectedYear.label +
              "-" +
              selectedMake.label +
              "-" +
              selectedModel.label +
              "(" +
              selectedType.label +
              ")",
            qualifier: item.label,
            oil: item.partsAndTypes.hasOwnProperty(OIL_FILTER)
              ? item.partsAndTypes[OIL_FILTER]
              : "",
            fuel: item.partsAndTypes.hasOwnProperty(FUEL_FILTER)
              ? item.partsAndTypes[FUEL_FILTER]
              : "",
            air: item.partsAndTypes.hasOwnProperty(AIR_FILTER)
              ? item.partsAndTypes[AIR_FILTER]
              : "",
            cAir: item.partsAndTypes.hasOwnProperty(CABIN_AIR_FILTER)
              ? item.partsAndTypes[CABIN_AIR_FILTER]
              : "",
            trans: item.partsAndTypes.hasOwnProperty(TRANSMISSION_FILTER)
              ? item.partsAndTypes[TRANSMISSION_FILTER]
              : "",
            breather: item.partsAndTypes.hasOwnProperty(BREATHER_FILTER)
              ? item.partsAndTypes[BREATHER_FILTER]
              : "",
            hydraulic: item.partsAndTypes.hasOwnProperty(HYDRAULIC_FILTER)
              ? item.partsAndTypes[HYDRAULIC_FILTER]
              : "",
            others: item.partsAndTypes.hasOwnProperty(OTHERS)
              ? item.partsAndTypes[OTHERS]
              : "",
          });
        });
      //result container
      const data = {
        val: true,
        searchType: APPLICATION_LOOKUP_TYPE,
        partNum: selectedModel.label,
        result: temp,
      };

      setApplicationData(temp);
      props.handleSearch(data);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  useEffect(() => {
    if (selectedModel && selectedModel.label) {
      getVehicleEnginesData();
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedEngine) {
      if (applicationData && applicationData.length > 0) {
        let tempapplicationData = applicationData.filter(
          (data) => data.qualifier === selectedEngine.label
        );
        const data = {
          val: true,
          searchType: APPLICATION_LOOKUP_TYPE,
          partNum: selectedModel.label,
          result: tempapplicationData,
        };
        props.handleSearch(data);
      }
    }
  }, [selectedEngine]);

  const handleDisabled = (type) => {
    switch (type) {
      case "Model Year":
        if (selectedType) {
          return false;
        } else {
          return true;
        }
        break;
      case "Make":
        if (selectedYear) {
          return false;
        } else {
          return true;
        }
        break;
      case "Model":
        if (selectedMake) {
          return false;
        } else {
          return true;
        }
        break;
      case "Engine":
        if (selectedModel) {
          return false;
        } else {
          return true;
        }
        break;
      case "Submodel":
        if (selectedModel) {
          return false;
        } else {
          return true;
        }
        break;
      default:
        return false;
    }
  };

  return (
    <Box className="fieldset-style-main">
      <form className="lg:mx-4 mb-4 ">
        <TextTypography text={APPLICATION_LOOKUP} />
        <Dropdown
          label={VEHICLE_TYPE}
          data={vehicleTypes}
          disabled={false}
          selectValue={setSelectedType}
          onOpen={() => getVehicleTypeData()}
          type = {APPLICATION_LOOKUP_TYPE}
          testid={VEHICLE_TYPE}
        />
        <Dropdown
          label={MODEL_YEAR}
          data={vehicleYears}
          onOpen={() => getVehicleYearData()}
          disabled={handleDisabled("Model Year")}
          selectValue={setSelectedYear}
          inverseSort={true}
          type = {APPLICATION_LOOKUP_TYPE}
          testid={MODEL_YEAR}
        />
        <Dropdown
          label={MAKE}
          data={vehicleMakes}
          onOpen={() => getVehicleMakesData()}
          disabled={handleDisabled("Make")}
          selectValue={setSelectedMake}
          type = {APPLICATION_LOOKUP_TYPE}
          testid={MAKE}
        />
        <Dropdown
          label={MODEL}
          data={vehicleModels}
          onOpen={() => getVehicleModelsData()}
          disabled={handleDisabled("Model")}
          selectValue={setSelectedModel}
          type = {APPLICATION_LOOKUP_TYPE}
          testid={MODEL}
        />
        <Dropdown
          label={ENGINE}
          data={vehicleEngines}
          disabled={handleDisabled("Engine")}
          selectValue={setSelectedEngine}
          type = {APPLICATION_LOOKUP_TYPE}
        />
        <Dropdown
          label={SUB_MODEL}
          data={vehicleSubModels}
          disabled={handleDisabled("Submodel")}
          selectValue={setSelectedSubModels}
          type = {APPLICATION_LOOKUP_TYPE}
        />
      </form>
      <SimpleSnackbar apiError={apiError} />
    </Box>
  );
});

export default ApplicationLookup;
