import { Box, FormControl } from "@mui/material";
import React, { useState, useEffect, memo } from "react";
import TextInput from "../common/textInput/TextInput";
import ButtonLayout from "../common/button/ButtonLayout";
import {
  COMPETITOR_INTERCHANGE,
  COMPETITOR_INTERCHANGE_TYPE,
  DISPLAY_FLEX_END,
  ERR_MSG_PART_NUMBER,
  MT_6,
  SEARCH_LABEL,
  WIDTH_100_px,
} from "../../utils/constants/GlobalConstants";
import { COMPETITOR_INTERCHANGE_DATA, VALID_SEARCH_REGEX } from "../../utils/constants/GlobalFormConstants";
import { TextTypography } from "../common/typography/TextTypography";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import { competitorInterchangeData } from "../../services/ProductServices";

const CompetitorInterchange = memo((props) => {
  const [data, setData] = useState(COMPETITOR_INTERCHANGE_DATA);
  const [apiError, setApiError] = useState({ val: false, messages: "" });

  useEffect(() => {
    if (props.reset !== COMPETITOR_INTERCHANGE_TYPE) {
      setData((data) => data.map((d) => (d.value ? { ...d, value: "" } : d)));
    }
  }, [props.reset]);
  const regex = VALID_SEARCH_REGEX;
  const handleCompetitorNums = (index, id, e) => {
    const val = e.target.value;
    const valid = val ? regex.test(val) : true;
    let competitorNums = [...data];
    competitorNums[index].value = e.target.value;
    competitorNums[index].valid = valid;
    setData(competitorNums);
  };

  const getCompetitorInterchangeData = async (searchArray) => {
    try {
      const res = await competitorInterchangeData({
        partNumbers: searchArray,
        apiName: "partNumberSearch",
      });
      return res;
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };

  const onPresCompetitorSearch = async () => {
    let searchArray = [];
    let validArr = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].value) {
        if (data[i].valid) {
          let split = data[i].value.split(",");
          for (let j = 0; j < split.length; j++) {
            searchArray.push(split[j]);
          }
        } else {
          validArr.push(data[i]);
        }
      }
    }
    if (validArr && validArr.length === 0) {
      let res;
      if (searchArray.length > 0) {
        res = await getCompetitorInterchangeData(searchArray);
      }
      const propsData = {
        val: true,
        searchType: COMPETITOR_INTERCHANGE_TYPE,
        partNumber: false,
        searchArray: searchArray,
        res: res?.partNumberDetailsList,
      };
      props.handleSearch(propsData);
    } else {
      setApiError((val) => ({ ...val, val: true, message: ERR_MSG_PART_NUMBER }));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onPresCompetitorSearch();
    }
  };

  return (
    <div className="zoom">
      <Box component="fieldset" className="fieldset-style-main">
        <form className="lg:mx-4 mb-4 ">
          <TextTypography text={COMPETITOR_INTERCHANGE} />
          <FormControl fullWidth>
            {data &&
              data.map((itm, i) => {
                return (
                  <div className="col-span-12 lg:col-span-5" key={i}>
                    <TextInput
                      className="h-10 p-4 bg-blue-50 dark:bg-slate-700 rounded-xl border-none focus:outline-none mb-3 w-full text-sm"
                      type={"text"}
                      placeholder={`Part Number ${itm.id}`}
                      required={true}
                      value={itm.value}
                      error={!itm.valid}
                      style={{
                        borderBottom: itm.valid ? 'inherit' : '1px solid red',
                      }}
                      testid="competitor"
                      onChange={(e) => handleCompetitorNums(i, itm.id, e)}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                );
              })}
          </FormControl>
          <Box sx={{ ...DISPLAY_FLEX_END }}>
            <ButtonLayout
              label={SEARCH_LABEL}
              sx={{ ...MT_6, ...WIDTH_100_px }}
              onClickBtn={() => onPresCompetitorSearch()}
            />
          </Box>
        </form>
      </Box>
      <SimpleSnackbar apiError={apiError} />
    </div>
  );
});

export default CompetitorInterchange;
