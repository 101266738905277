/* eslint-disable no-unused-vars */
/* eslint-disable  react-hooks/exhaustive-deps */

import React, { useState, useRef, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useTheme, useMediaQuery } from "@mui/material";
import {
  FILTER_BY_SIZE_DROPDOWN_DATA,
  IMG_MAP,
  QUALIF_LABELS,
} from "../../utils/constants/GlobalFormConstants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DataTable from "../common/dataTable/DataTable";
import "./styles/FilterLookupBySize.css";
import DataList from "../common/dataList/DataList";
import { Dropdown } from "../common/Dropdown/Dropdown";
import { ACC_TABLE_ROW, ACCORDION_Details, ACCORDION_SUMMARY, BORDER_BOTTOM_NONE, COLOR_000, dimesionTableStyle, DISCLAIMER_TEXT, FILTER_BY_SIZE_TYPE, FONT500, FONT_SIZE_1REM, MR_10, SELECET_DIMENSION_TXT, SPEC_HEIGHT, SPEC_LENGTH } from "../../utils/constants/GlobalConstants";
import SimpleSnackbar from "../common/snackBar/SnackBar";
import {
  getDimensionDetails,
  getPartTypeChange,
} from "../../services/FilterByDimensionsService";

function AccordionTable({
  onhandleDimentionChange,
  qualification,
  measurementValues,
  onDimensionDataChange,
  dimensionData,
  ...props
}) {
  const [expanded, setExpanded] = useState(true);
  const [DimentionChange, setDimentionChange] = useState(0);
  const detailsRef = useRef(null);
  const qualificationLabels = QUALIF_LABELS;
  const [apiError, setApiError] = useState({ val: false, messages: "" });

  const handleAccordionChange = () => {
    setExpanded(!expanded);
    if (!expanded && detailsRef.current) {
      detailsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const getDimensionDetailsData = async (index) => {
    try {
      let qualificationValue = measurementValues[index];
      const dimensionDetails = await getDimensionDetails(
        props.partType,
        props.SelectedStyle,
        qualificationValue,
        qualification
      );
      onDimensionDataChange(dimensionDetails);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const handleDimentionChange = (index) => {
    setDimentionChange(index);
    onhandleDimentionChange(index);
    getDimensionDetailsData(index);
  };
  useEffect(() => {
    setDimentionChange(0);
  }, [measurementValues]);

  return (
    <div>
      <Accordion expanded={expanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
           ...ACCORDION_SUMMARY
          }}
        >
          <Typography
            sx={{  ...COLOR_000, ...FONT500, ...FONT_SIZE_1REM }}
          >
            {qualification
              ? `Select ${qualificationLabels[qualification] ? qualificationLabels[qualification] : 'Dimensions'}`
              : SELECET_DIMENSION_TXT}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          ref={detailsRef}
          sx={{
           ...ACCORDION_Details
          }}
        >
          <Table sx={{...BORDER_BOTTOM_NONE}}>
            <TableRow
              sx={{ ...ACC_TABLE_ROW }}
            >
              {measurementValues &&
                measurementValues.map((value, index = 0) => (
                  <TableCell
                    key={index}
                    onClick={() => handleDimentionChange(index)}
                    sx={{
                      backgroundColor:
                        DimentionChange === index
                          ? "rgb(37 99 235) "
                          : "rgb(239 246 255)",
                      color: DimentionChange === index ? "white" : "inherit",
                      ...dimesionTableStyle
                    }}
                  >
                    {value}
                  </TableCell>
                ))}
            </TableRow>
          </Table>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

const FilterLookupBySize = memo(({ qualification, measurementValues, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [partType, setPartType] = useState(props.partType);
  const [SelectedStyle, setSelectedStyle] = useState(props.style);
  const [DimentionChange, setDimentionChange] = useState(0);
  const [partData, setPartData] = useState(props.partData);
  const [styleData, setStyleData] = useState(props.styleData);
  const [dimensionData, setDimensionData] = useState([]);
  const [apiError, setApiError] = useState({ val: false, messages: "" });
  const [formattedValues, setFormattedValues] = useState([]);
  const [imageSrc, setImageSrc] = useState([]);
  const qualificationLabels = QUALIF_LABELS;

  const imageMap = IMG_MAP;

  const importAll = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item));
    });
    return images;
  };

  const images = importAll(
    require.context(
      "../../../src/assets/images",
      false,
      /\.(png|jpeg|jpg|gif)$/
    )
  );

  useEffect(() => {
    if (props.reset !== FILTER_BY_SIZE_TYPE) {
      setPartType(null);
      setSelectedStyle(null);
    }
  }, [props.reset]);


 useEffect(() => {
     setSelectedStyle(null); 
  }, [partType]);

  useEffect(() => {

    getDimensionDetailsData();

  }, [measurementValues]);

  useEffect(() => {
    setPartType(props.partType);
    setSelectedStyle(props.style);
    setStyleData(props.styleData)
  }, [props.partType, props.style]);

  useEffect(() => {
    updateImageSrc(partType, SelectedStyle);
  }, [partType, SelectedStyle]);


  const getDimensionDetailsData = async () => {
   
    try {
      const qualificationValue = measurementValues[0];
      const dimensionDetails = await getDimensionDetails(
        props.partType,
        SelectedStyle,
        qualificationValue,
        qualification
      );
      setDimensionData(dimensionDetails);
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };
  const handlePartTypeChange = async (selectedValue) => {
    setPartType(selectedValue);
    props.onPartTypeSelect(selectedValue, null);

    try {
      const payload = {
        partType: selectedValue,
        apiName: "style",
      };
      const response = await getPartTypeChange(payload);

      const styles = response.styles.map((item) => ({
        value: item,
        label: item,
      }));

      setStyleData(styles);
      setSelectedStyle(null);
      props.onstyleSelect(null, partData, styles);

    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };
  const getMeasurements = async (selectedValue) => {
  
    let formattedData;
    const payload = {
      partType: partType,
      apiName: "measurements",
      style: selectedValue
    };
    try {
      const response = await getPartTypeChange(payload);
      if (response && response.measurements) {
        const { qualification, measurementValues } = response.measurements;

        if (
          qualification ===  SPEC_HEIGHT ||
          qualification === SPEC_LENGTH
        ) {
          formattedData = Array.from(
            new Set(
              measurementValues.map((val) => {
                const numericPart = val.split(" ")[0];
                return Math.floor(parseFloat(numericPart));
              })
            )
          ).map(String);
        } else {
          formattedData = measurementValues;
        }
        setFormattedValues(formattedData);

        props.onMeasurementsFetched({
          qualification: qualification,
          measurementValues: formattedData,
        });
      }
    } catch (error) {
      setApiError((val) => ({ ...val, val: true, message: error.message }));
    }
  };

  const handleDimensionDataChange = (data) => {
    setDimensionData(data);
  };

  const handleStyleChange = async (selectedValue) => {
   
    if (!selectedValue) {
      return;
    }
    setSelectedStyle(selectedValue);
    const data = {
      val: true,
      searchType: "filterbysize1",
    };
    props.handleSearch(data);
    props.onstyleSelect(selectedValue, partData, styleData);
    getMeasurements(selectedValue);
  };

  const handleDimentionChange = (number) => {
    setDimentionChange(number);
  };

  const updateImageSrc = (partType, style) => {
    const imageNames = imageMap[partType]?.[style] || ["filter1.jpg"];
    const newImageSrcs = imageNames.map((imageName) => images[imageName]);
    setImageSrc(newImageSrcs);
  };

  return (
    <>
      <div className="disclaimer-text">{DISCLAIMER_TEXT}</div>
      <div className={isMobile ? "column-flex" : "row-flex"}>
        <Box className="dropdown-container">
          <Box className="dropdown-item">
            <Dropdown
              label=" Part Type"
              data={FILTER_BY_SIZE_DROPDOWN_DATA}
              value={partType ? partType : props.partType}
              selectValue={(selectedItem) =>
                handlePartTypeChange(selectedItem.label)
              }
            />
          </Box>
          <Box className="dropdown-item">
            <Dropdown
              label=" Style"
              data={styleData}
              value={SelectedStyle ? SelectedStyle : null}
              selectValue={(selectedItem) =>
                handleStyleChange(selectedItem.label)
              }
            />
          </Box>
        </Box>
        {SelectedStyle && <Box className ="img-container">
          {imageSrc.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Thumbnail ${index + 1}`}
              style={{...MR_10 }}
            />
          ))}
        </Box>}
      </div>
      {SelectedStyle && <div className ="accordion-container">
        <AccordionTable
          onhandleDimentionChange={handleDimentionChange}
          dimensionData={dimensionData}
          qualification={qualification}
          measurementValues={measurementValues}
          partType={partType ? partType : props.partType}
          SelectedStyle={SelectedStyle}
          onDimensionDataChange={handleDimensionDataChange}
        />
      </div>}
      {SelectedStyle && <div className="result-table">
        {isMobile ? (
          <DataList
            source = {FILTER_BY_SIZE_TYPE}
            SelectedStyle={SelectedStyle}
            DimentionChange={DimentionChange}
            onPressLink={(val) => props.onPressLink(val)}
            dimensionData={dimensionData}
          />
        ) : (
          <DataTable
            source= {FILTER_BY_SIZE_TYPE}
            SelectedStyle={SelectedStyle}
            qualification={qualificationLabels[qualification]}
            DimentionChange={DimentionChange}
            onPressLink={(val) => props.onPressLink(val)}
            dimensionData={dimensionData}
          />
        )}
      </div>}
      <SimpleSnackbar apiError={apiError} />
    </>
  );
});
export default FilterLookupBySize;
