/* eslint-disable  react-hooks/exhaustive-deps */
import * as React from "react";
import Box from "@mui/material/Box";
import {
  ALL_APPLICATIONS_LIST_TYPE,
  APPLICATION_LOOKUP_TYPE,
  BORDER_BTM_BLACK,
  BORDER_TOP_3REMBLACK,
  CANISTER_STYLE,
  CARTRIDGE_STYLE,
  CONE_STYLE,
  CYLINDRICAL_STYLE,
  FILTER_ABBR,
  FILTER_BY_SIZE_TYPE,
  FILTER_SEARCH_TYPE,
  FONT_SIZE_0875REM,
  GASKET_DIAMETER_TYPE,
  INLINE_STYLE,
  NO_DATA_FOUND_TEXT,
  PANEL_STYLE,
  PARTIAL_SEARCH_TYPE,
  QUICK_SEARCH_TYPE,
  ROUND_SEAL_STYLE,
  ROUND_STYLE,
  SHOP_BTN_LIST_ITEM,
  SHOP_BTN_STYLE,
  SHOP_ONLINE_BTN,
  SHOP_PROLINK_BTN,
  SPINON_STYLE,
  STOCK_LABEL,
  UNKNOWN_VALUE,
  WIDTH_100,
} from "../../../utils/constants/GlobalConstants";
import { List, ListItem, ListItemText } from "@mui/material";
import LinkView from "../link/LinkView";
import ButtonLayout from "../button/ButtonLayout";
import "./DataList.css";
import { getRequest } from "../../../services/RequestConfig";
import SimpleSnackbar from "../snackBar/SnackBar";
import {
  APP_LOOKUP_HEADERS,
  FILTER_BY_SIZE_HEADERS,
  FILTER_SEARCH_HEADERS,
  GASKET_DIAMETER_HEADERS,
  PARTIAL_SEARCH_HEADERS,
  PART_APP_HEADERS,
  QUICK_SEARCH_HEADERS,
  FILTER_BY_SIZE_HEADERS_INLINE,
  FILTER_BY_SIZE_HEADERS_CATRIDGE,
  FILTER_BY_SIZE_HEADERS_SPINON,
  FILTER_BY_SIZE_HEADERS_PANEL,
  FILTER_BY_SIZE_HEADERS_ROUND,
  NAPA_PROLINK_URL,
  NAPA_ONLINE_URL,
} from "../../../utils/constants/GlobalFormConstants";

function createApplicationLookUpData(
  MakeModel,
  Qualifier,
  Oil,
  Fuel,
  Air,
  CAir,
  Trans,
  Breather,
  Hydraulic,
  Others
) {
  return {
    MakeModel,
    Qualifier,
    Oil,
    Fuel,
    Air,
    CAir,
    Trans,
    Breather,
    Hydraulic,
    Others,
  };
}

const DataList = React.memo((props) => {
  const [rows, setRows] = React.useState([]);
  const [fullData, setFullData] = React.useState([]);

  const [apiError, setApiError] = React.useState({ val: false, messages: "" });

  const onPressLink = async (prtNum, index) => {
    let productDetails;
    try {
      if (props.source === APPLICATION_LOOKUP_TYPE) {
        productDetails = await getRequest(
          `filters/specifications?productIdPartNumber=${prtNum?.productIdPartNumber}`
        );
      } else if (props.source === FILTER_BY_SIZE_TYPE) {
        productDetails = await getRequest(
          `filters/specifications?productIdPartNumber=${fullData[index]?.productIdPartNumber}`
        );
      } else {
        productDetails = await getRequest(
          `filters/specifications?productIdPartNumber=${fullData[index]?.productIdPartNumber}`
        );
      }
      props.onPressLink(productDetails?.data);
    } catch (err) {
      setApiError((val) => ({ ...val, val: true, message: err.message }));
    }
  };

  const sortFilters = (type) => {
    const sortedList =
      type?.length > 0 &&
      type.sort((a, b) =>
        a.partNumbers.toUpperCase() > b.partNumbers.toUpperCase() ? 1 : -1
      );
    return sortedList ? sortedList : [];
  };

  const getApplicationLookupData = () => {
    console.log("props.data", props.data);
    let temp = [];
    props?.data &&
      props?.data.forEach((item) => {
        temp.push(
          createApplicationLookUpData(
            item.makeModel,
            item.qualifier,
            sortFilters(item.oil),
            sortFilters(item.fuel),
            sortFilters(item.air),
            sortFilters(item.cAir),
            sortFilters(item.trans),
            sortFilters(item.breather),
            sortFilters(item.hydraulic),
            sortFilters(item.others)
          )
        );
      });
    setRows(temp);
    return temp;
  };
  const getFilterBySizeData = () => {
    let temp = [];
    props?.dimensionData &&
    (props?.SelectedStyle === CARTRIDGE_STYLE ||
      props?.SelectedStyle === CONE_STYLE ||
      props?.SelectedStyle === CYLINDRICAL_STYLE ||
      props?.SelectedStyle === CANISTER_STYLE ||
      props?.SelectedStyle === ROUND_STYLE ||
      props?.SelectedStyle === ROUND_SEAL_STYLE)
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            bottomInsideDiameter: item.bottomInsideDiameter,
            bottomOutsideDiameter: item.bottomOutsideDiameter,
            topOutsideDiameter: item.topOutsideDiameter,
            topInsideDiameter: item.topInsideDiameter,
          });
        })
      : props?.dimensionData && props?.SelectedStyle === PANEL_STYLE
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            length: item.length,
            width: item.width,
          });
        })
      : props?.dimensionData && props?.SelectedStyle === SPINON_STYLE
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            threadSize: item.threadSize,
            topOutsideDiameter: item.topOutsideDiameter,
            gasketIncluded: item.gasketIncluded,
            gasketInsideDiameter: item.gasketInsideDiameter,
            gasketOutletDiameter: item.gasketOutletDiameter,
          });
        })
      : props?.dimensionData && props?.SelectedStyle === INLINE_STYLE
      ? props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            topOutsideDiameter: item.topOutsideDiameter,
            inlet: item.inlet,
            outlet: item.outlet,
          });
        })
      : props?.dimensionData &&
        props?.dimensionData.forEach((item) => {
          temp.push({
            partNumber: item.partNumber,
            height: item.height,
            length: item.length,
            threadSize: item.threadSize,
            width: item.width,
            bottomInsideDiameter: item.bottomInsideDiameter,
            bottomOutsideDiameter: item.bottomOutsideDiameter,
            topOutsideDiameter: item.topOutsideDiameter,
            topInsideDiameter: item.topInsideDiameter,
            gasketIncluded: item.gasketIncluded,
            gasketInsideDiameter: item.gasketInsideDiameter,
            gasketOutletDiameter: item.gasketOutletDiameter,
          });
        });
    setRows(temp);
  };
  React.useEffect(() => {
    let filteredAbbr= FILTER_ABBR;
    if (props.source === FILTER_BY_SIZE_TYPE) {
      setFullData(props?.dimensionData);
    } 
    if (props?.source === FILTER_SEARCH_TYPE) {
      let filterAbbrData= props?.filterSearchRes?.filter((item)=> filteredAbbr?.includes(item?.abbreviation) )
      setFullData(filterAbbrData)
      let filteredData = filterAbbrData?.map(
        ({
          partNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          fieldSku,
          leadTime,
        }) => ({
          partNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          fieldSku,
          leadTime:STOCK_LABEL,
        })
      );
      setRows(filteredData);
    } else if (props?.source === PARTIAL_SEARCH_TYPE) {
      
      let filterAbbrData= props?.filterSearchRes?.filter((item)=> filteredAbbr?.includes(item?.abbreviation) )
      setFullData(filterAbbrData)

      let filteredData = filterAbbrData?.map(
        ({
          partNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          leadTime,
        }) => ({
          partNumber,
          manufacturer,
          abbreviation,
          napaPartNumber,
          leadTime:STOCK_LABEL,
        })
      );
      setRows(filteredData);
    } else if (props?.source === ALL_APPLICATIONS_LIST_TYPE) {
      const modifiedData = props?.filterSearchRes?.flatMap(
        (car) =>
          car?.engines !== null &&
          car?.engines?.map((engine) => ({
            make: car.make,
            model: car.model,
            startYear: car.startYear,
            endYear: car.endYear,
            engine: engine,
          }))
      );
      let filteredData = modifiedData?.map(({ make, model, engine }) => ({
        make,
        model,
        engine,
      }));
      setRows(filteredData);
    } else if (props.source === APPLICATION_LOOKUP_TYPE) {
      getApplicationLookupData();
    } else if (props.source === FILTER_BY_SIZE_TYPE) {
      getFilterBySizeData();
    } else if (props.source === GASKET_DIAMETER_TYPE) {
      let sampleArr = props?.data?.map(
        ({ gasketOutletDiameter, gasketInsideDiameter, gasketThickness }) => ({
          gasketOutletDiameter,
          gasketInsideDiameter,
          gasketThickness,
        })
      );

      if (
        sampleArr.length > 0 &&
        ((sampleArr[0]?.gasketInsideDiameter === UNKNOWN_VALUE &&
          sampleArr[0]?.gasketOutletDiameter === UNKNOWN_VALUE &&
          sampleArr[0]?.gasketThickness === UNKNOWN_VALUE) ||
          (sampleArr[0]?.gasketInsideDiameter === "" &&
            sampleArr[0]?.gasketOutletDiameter === "" &&
            sampleArr[0]?.gasketThickness === ""))
      ) {
        setRows([]);
      } else {
        setRows(sampleArr);
      }
    }
  }, [
    props?.source,
    props?.inputPartNum,
    props?.dimensionData,
    props.data,
    props?.filterSearchRes,
  ]);
  const setupDimensionHeaders = (style) => {
    const headerStyle =
      style === CARTRIDGE_STYLE ||
      style === CONE_STYLE ||
      style === CYLINDRICAL_STYLE ||
      style === CANISTER_STYLE
        ? FILTER_BY_SIZE_HEADERS_CATRIDGE
        : style === ROUND_STYLE || style === ROUND_SEAL_STYLE
        ? FILTER_BY_SIZE_HEADERS_ROUND
        : style === PANEL_STYLE
        ? FILTER_BY_SIZE_HEADERS_PANEL
        : style === SPINON_STYLE
        ? FILTER_BY_SIZE_HEADERS_SPINON
        : style === INLINE_STYLE
        ? FILTER_BY_SIZE_HEADERS_INLINE
        : FILTER_BY_SIZE_HEADERS;
    return headerStyle;
  };
  const headCells =
    props.source === FILTER_SEARCH_TYPE
      ? FILTER_SEARCH_HEADERS
      : props.source === QUICK_SEARCH_TYPE
      ? QUICK_SEARCH_HEADERS
      : props.source === ALL_APPLICATIONS_LIST_TYPE
      ? PART_APP_HEADERS
      : props.source === GASKET_DIAMETER_TYPE
      ? GASKET_DIAMETER_HEADERS
      : props.source === APPLICATION_LOOKUP_TYPE
      ? APP_LOOKUP_HEADERS
      : props.source === FILTER_BY_SIZE_TYPE
      ? setupDimensionHeaders(props.SelectedStyle)
      : PARTIAL_SEARCH_HEADERS;
  return (
    <>
      <Box sx={WIDTH_100}>
        <List>
          {rows && rows?.length > 0 ? (
            rows.map((data, ind) => (
              <Box sx={BORDER_TOP_3REMBLACK}>
                {headCells.map((item, index) => {
                  return (
                    <ListItem key={index} sx={BORDER_BTM_BLACK}>
                      <ListItemText
                        primaryTypographyProps={{ style: SHOP_BTN_LIST_ITEM }}
                        primary={item.label}
                      />
                      <Box sx={SHOP_BTN_STYLE}>
                        {props.source === FILTER_SEARCH_TYPE && index === 4 ? (
                          <LinkView>
                            <ButtonLayout
                              className="full-width"
                              testid="proLinkBtn"
                              onClickBtn={() => {
                                window.open(
                                  `${NAPA_PROLINK_URL}${data?.fieldSku}`,
                                  "_blank"
                                );
                              }}
                              color="primary"
                              label={SHOP_PROLINK_BTN}
                            />
                            <ButtonLayout
                              className="full-width"
                              testid="onLineBtn"
                              onClickBtn={() => {
                                window.open(
                                  `${NAPA_ONLINE_URL}${data?.fieldSku}`,
                                  "_blank"
                                );
                              }}
                              color="primary"
                              label={SHOP_ONLINE_BTN}
                            />
                          </LinkView>
                        ) : (props.source === FILTER_SEARCH_TYPE &&
                            index === 3) ||
                          (props.source === QUICK_SEARCH_TYPE &&
                            index !== 0 &&
                            index !== 1) ||
                          (props.source === PARTIAL_SEARCH_TYPE &&
                            index === 3) ||
                          (props.source === FILTER_BY_SIZE_TYPE &&
                            index === 0) ? (
                          <LinkView>
                            <div
                              onClick={() =>
                                onPressLink(data?.napaPartNumber, ind)
                              }
                              testid="dataItem"
                            >
                              {" "}
                              {data[item.id]?.toUpperCase()}
                            </div>
                          </LinkView>
                        ) : props.source === APPLICATION_LOOKUP_TYPE &&
                          index > 1 ? (
                          data[item.id].length > 0 &&
                          data[item.id].map((filter, i) => (
                            <div>
                              <LinkView key={i}>
                                <div
                                  onClick={() => onPressLink(filter)}
                                  testid="partNums"
                                >
                                  {" "}
                                  {filter.partNumbers}
                                </div>
                              </LinkView>
                            </div>
                          ))
                        ) : (
                          data[item.id]?.toUpperCase()
                        )}
                      </Box>
                    </ListItem>
                  );
                })}
              </Box>
            ))
          ) : (
            <Box className="p-3" sx={FONT_SIZE_0875REM}>
              {NO_DATA_FOUND_TEXT}
            </Box>
          )}
        </List>
      </Box>
      <SimpleSnackbar apiError={apiError} />
    </>
  );
});
export default DataList;
