import { Typography } from "@mui/material";
import {
  COLOR_000,
  FONT500,
  FONT_SIZE_125REM,
  LINE_HGT_175,
  MB_12,
} from "../../../utils/constants/GlobalConstants";

export const TextTypography = (props) => {
  return (
    <Typography
      sx={
        props.sx
          ? { ...COLOR_000, ...FONT500, ...props.sx }
          : {
              ...MB_12,
              ...COLOR_000,
              ...FONT500,
              ...FONT_SIZE_125REM,
              ...LINE_HGT_175,
              ...props,
            }
      }
    >
      {props.text}
    </Typography>
  );
};
