/* eslint-disable no-unused-vars */
import axios from "axios";
let baseURL=process.env.REACT_APP_API_BASE_URL
export const axiosClient = axios.create();
axiosClient.defaults.baseURL = baseURL;
axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  crossDomain: true,
  "Access-Control-Allow-Headers":"DNT,User-Agent,If-Modified-Since,Cache-Control,Content-Type,Range,origin, x-requested-with, accept",
};


export function getRequest(URL) {
  return axiosClient.get(`/${URL}`).then((response) => response).catch((err) => { throw err });
}


export function postRequest(URL, payload) {
  return axiosClient.post(`/${URL}`, payload).then((response) => response).catch((err) => { throw err });
}

